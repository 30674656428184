import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Logo from '../../assets/img/Logo  (1).svg';

export default function ButtonAppBar() {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: 4,
          width: '90%',
          height: '8%',
          maxWidth: 1300,
          margin: '0 auto',
          top: 10,
          left: 0,
          right: 0,
          boxShadow: '0 7px 18px rgba(0, 0, 0, 0.4)',
        }}
      >
        <Toolbar>
          <img
            src={Logo}
            alt="Logo"
            style={{
              height: 95,
              marginRight: '16px',
              marginTop: '1%',
            }}
          />

          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="#4364f7"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMenuClick}
                style={{ color: '#0a798d', margin: '5% 50%' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem
                  onClick={() => scrollToSection('quem-somos')}
                  style={{ color: '#0a798d', fontFamily: 'Poppins' }}
                >
                  Quem Somos
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('soluções')}
                  style={{ color: '#0a798d', fontFamily: 'Poppins' }}
                >
                  Soluções
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('faq')} style={{ color: '#0a798d', fontFamily: 'Poppins' }}>
                  Perguntas Frequentes
                </MenuItem>
                <Button
                  component={Link}
                  to="https://homol.quickmei.com.br/login"
                  color="inherit"
                  sx={{
                    marginLeft: '5%',
                    color: '#fff',
                    fontFamily: 'Poppins',
                    backgroundColor: '#0a798d',
                    textTransform: 'none',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#00465a',
                    },
                  }}
                >
                  Login
                </Button>
              </Menu>
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 8,
                  color: '#0a798d',
                  fontFamily: 'Poppins',
                  cursor: 'pointer',
                  fontSize: { xs: '5px', sm: '8px', md: '18px', lg: '20px' },
                  textAlign: { xs: 'center', sm: 'left' },
                  padding: { xs: '10px 0', sm: '0' },
                }}
                onClick={() => scrollToSection('quem-somos')}
              >
                Quem Somos
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 8,
                  color: '#0a798d',
                  fontFamily: 'Poppins',
                  cursor: 'pointer',
                  fontSize: { xs: '5px', sm: '8px', md: '18px', lg: '20px' },
                  textAlign: { xs: 'center', sm: 'left' },
                  padding: { xs: '10px 0', sm: '0' },
                }}
                onClick={() => scrollToSection('soluções')}
              >
                Soluções
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 69,
                  color: '#0a798d',
                  fontFamily: 'Poppins',
                  cursor: 'pointer',
                  fontSize: { xs: '5px', sm: '8px', md: '18px', lg: '20px' },
                  textAlign: { xs: 'center', sm: 'left' },
                  padding: { xs: '10px 0', sm: '0' },
                }}
                onClick={() => scrollToSection('faq')}
              >
                Perguntas Frequentes
              </Typography>
              <Button
                component={Link}
                to="https://homol.quickmei.com.br/login"
                color="inherit"
                sx={{
                  color: '#fff',
                  fontFamily: 'Poppins',
                  backgroundColor: '#0a798d',
                  textTransform: 'none',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: '#00465a',
                  },
                }}
              >
                Login
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}
