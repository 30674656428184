import React, { useState, useEffect } from 'react';
import { isEmail } from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { useMediaQuery, Button, Checkbox, TextField, FormControlLabel, Grid } from '@mui/material';
import Layout from '../../components/Bug/Layout';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';
import axios from '../../services/axios';
import { StyledContainer, Container } from './styled';

export default function Register() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const idUser = useSelector((state) => state.auth.user.id);
  console.log(idUser);
  const history = useHistory();
  const { id } = useParams(); // Obtém o ID do cliente dos parâmetros da URL

  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    documentoTipo: 'CPF',
    nome: '',
    documento: '',
    telefone: '',
    email: '',
    inscricao: '',
    cep: '',
    cidade: '',
    logradouro: '',
    numero: '',
    UF: '',
    bairro: '',
    complemento: '',
    emissao: false,
  });
  const [formErrors, setFormErrors] = useState({
    nome: false,
    email: false,
  });

  // Fetch client data when component mounts
  useEffect(() => {
    console.log('clienteId:', id); // Log the clienteId
    if (id) {
      axios
        .get(`/client/${id}`)
        .then((response) => {
          console.log('Response:', response); // Log the entire response
          setFormData(response.data);
        })
        .catch((error) => {
          console.error('There was an error fetching the client data!', error);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {};

    if (!formData.nome || formData.nome.length < 3 || formData.nome.length > 255) {
      newFormErrors.nome = true;
    } else {
      newFormErrors.nome = false;
    }

    if (!formData.email || !isEmail(formData.email)) {
      newFormErrors.email = true;
    } else {
      newFormErrors.email = false;
    }

    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      return;
    }

    dispatch(
      actions.editClientRequest({
        ...formData,
        history,
        id,
      }),
    );
  };

  const getMask = () => {
    return formData.documentoTipo === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99';
  };

  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  return (
    <Layout>
      <Container className={isSmallScreen ? 'small-screen' : ''}>
        <Loading isLoading={isLoading} />
        <StyledContainer>
          <form onSubmit={handleSubmit}>
            <h1>Edite um cliente</h1>
            {formStep === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Nome *"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    placeholder="Nome"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={formErrors.nome}
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{
                      disableUnderline: true,
                      inputComponent: InputMask,
                      inputProps: {
                        mask: '(99) 99999 9999',
                        value: formData.telefone,
                        onChange: handleChange,
                        maskChar: ' ',
                        name: 'telefone',
                      },
                    }}
                    label="Celular *"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleChange}
                    placeholder="Seu Celular"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="E-mail *"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Seu e-mail"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={formErrors.email}
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    select
                    label="Documento"
                    name="documentoTipo"
                    value={formData.documentoTipo}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    SelectProps={{
                      native: true,
                    }}
                    style={{ width: '30%' }}
                  >
                    <option value="CPF">CPF</option>
                    <option value="CNPJ">CNPJ</option>
                  </TextField>
                  <InputMask mask={getMask()} value={formData.documento} onChange={handleChange} name="documento">
                    {() => (
                      <TextField
                        className="text-field"
                        InputProps={{ disableUnderline: true }}
                        label={formData.documentoTipo}
                        name="documento"
                        value={formData.documento}
                        onChange={handleChange}
                        placeholder={`Seu ${formData.documentoTipo}`}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        style={{ boxShadow: 'none', width: '70%' }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Inscrição Estadual *"
                    name="inscricao"
                    value={formData.inscricao}
                    onChange={handleChange}
                    placeholder="Inscrição Estadual"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="CEP *"
                    name="cep"
                    value={formData.cep}
                    onChange={handleChange}
                    placeholder="CEP"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Cidade *"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleChange}
                    placeholder="Cidade"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Logradouro *"
                    name="logradouro"
                    value={formData.logradouro}
                    onChange={handleChange}
                    placeholder="Logradouro"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Número *"
                    name="numero"
                    value={formData.numero}
                    onChange={handleChange}
                    placeholder="Número"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="UF *"
                    name="UF"
                    value={formData.UF}
                    onChange={handleChange}
                    placeholder="UF"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Bairro *"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                    placeholder="Bairro"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Complemento"
                    name="complemento"
                    value={formData.complemento}
                    onChange={handleChange}
                    placeholder="Complemento"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className="FormControlLabel"
                    control={
                      <Checkbox checked={formData.emissao} onChange={handleChange} name="emissao" color="primary" />
                    }
                    label={<span style={{ fontFamily: 'Poppins' }}>Deseja emitir?</span>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={handleNextStep}>
                    Próximo
                  </Button>
                  <Grid item xs={12} md={4}>
                    <Button variant="contained" color="primary" onClick={() => history.push('/adjust-client')}>
                      Voltar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {formStep === 2 && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h4>Confirmação de Dados</h4>
                    <p>Confira se os dados abaixo estão corretos antes de prosseguir.</p>
                  </Grid>
                  {Object.keys(formData).map((key) => (
                    <Grid item xs={12} md={4} key={key}>
                      <TextField
                        className="text-field"
                        InputProps={{ disableUnderline: true }}
                        label={key}
                        name={key}
                        value={formData[key]}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        disabled
                        style={{ boxShadow: 'none' }}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handlePrevStep}>
                      Voltar
                    </Button>
                    {formStep === 2 && (
                      <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                          Confirmar e Salvar
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        </StyledContainer>
      </Container>
    </Layout>
  );
}
