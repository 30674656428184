import React from 'react';
import { Container, Typography, Box, keyframes } from '@mui/material';
import personagem from '../../assets/img/Logo  (1).svg';

const slideUp = keyframes`
  0% {
    transform: translateY(20px); /* Mover 20px para baixo */
    opacity: 0; /* Iniciar transparente */
  }
  100% {
    transform: translateY(0); /* Retornar à posição original */
    opacity: 1; /* Tornar visível */
  }
`;

function HeroSection() {
  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #0a798d, #0052d4, #4364f7, #6fb1fc, #00d2ff, #00d2ff)',
        padding: '15% 0',
        margin: '2% 0%',
        textAlign: 'center',
        clipPath: 'polygon(0 0, 100% 0%, 100% 85%, 0 100%)',
        position: 'relative',
      }}
    >
      <Container>
        <Typography
          id="home"
          variant="h2"
          gutterBottom
          sx={{
            color: 'white',
            textAlign: 'start',
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            lineHeight: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
            animation: `${slideUp} 2.9s ease forwards`,
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)', // Sombra sutil
          }}
        >
          Bem-vindo ao <br /> QuickMei
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: 'white',
            textAlign: 'start',
            fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' },
            animation: `${slideUp} 2.9s ease forwards`,
            fontFamily: 'Poppins',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)', // Sombra sutil
          }}
        >
          Ágil e Feito para o Sucesso do Seu Negócio!
        </Typography>
      </Container>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          maxHeight: { xs: '10vh', md: '30vh' },
          paddingBottom: { xs: '0', md: '20px' },
          alignItems: 'flex-start', // Alinhando o avatar no topo
          padding: { xs: '30px 0', md: '20px' }, // Adiciona padding em telas menores // Ajuste do padding se necessário
          animation: `${slideUp} 2.9s ease forwards`,
        }}
      >
        <img
          src={personagem}
          alt="Personagem"
          style={{
            maxWidth: '30%',
            height: 'auto',
            maxHeight: { xs: '10vh', md: '10vh' },
            margin: '-20% 10%', // Removendo margens negativas
            objectFit: 'contain', // Mantém a proporção da imagem
            animation: `${slideUp} 0.5s ease forwards`,
          }}
        />
      </Box>
    </Box>
  );
}

export default HeroSection;
