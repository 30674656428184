import styled from 'styled-components';
import { Table, Button } from '@mui/material';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isSmallScreen ? '10%' : '10%')};

  @media (max-width: 768px) {
    margin-top: ${(props) => (props.isSmallScreen ? '40%' : '40%')};
  }

  .button {
    width: ${(props) => (props.isSmallScreen ? '100%' : '350px')};
    height: 40px;
    border-radius: 35px;
    text-transform: none;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }

    @media (max-width: 768px) {
      width: 90%;
      max-width: 100%;
    }
  }
`;

export const ResponsiveButton = styled(Button)`
  width: ${(props) => (props.isSmallScreen ? '100%' : 'auto')};
  font-size: ${(props) => (props.isSmallScreen ? '14px' : '16px')};
  padding: ${(props) => (props.isSmallScreen ? '10px' : '12px 24px')};
  text-align: center;
  .button {
    width: ${(props) => (props.isSmallScreen ? '100%' : '350px')};
    height: 40px;
    border-radius: 35px;
    text-transform: none;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }

    @media (max-width: 768px) {
      width: 90%;
      max-width: 100%;
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  max-width: 1000px;
  padding: 0 15px; /* Adiciona um pouco de padding para evitar que o conteúdo grude nas bordas */

  h1 {
    font-size: 24px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    color: #333;
  }

  p {
    font-size: 15px;
    text-align: center;
    margin: 0 70px;
    font-family: 'Poppins', sans-serif;
    margin-top: ${(props) => (props.isSmallScreen ? '5%' : '0%')};
    color: #333;
  }

  h4 {
    font-size: 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
    color: #333;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  font-family: 'Poppins', sans-serif;
  overflow-x: auto; /* Adiciona rolagem horizontal em telas pequenas */
  margin: 20px 0;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

export const StyledTable = styled(Table)`
  width: 100%;
  font-family: 'Poppins', sans-serif;
  border-collapse: collapse; /* Remove espaços entre bordas das células */

  th,
  td {
    text-align: left;
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    border-bottom: 1px solid #ddd; /* Linha divisória entre linhas */
  }

  th {
    background-color: #f4f4f4; /* Cor de fundo para o cabeçalho */
    font-weight: bold;
  }

  tr:hover {
    background-color: #f5f5f5; /* Destaque na linha ao passar o mouse */
  }

  @media (max-width: 768px) {
    th,
    td {
      padding: 8px; /* Ajusta o padding para telas menores */
      font-size: 14px; /* Ajusta o tamanho da fonte para telas menores */
    }
  }
`;

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 40vh;
  height: 30vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: hidden;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;

  button {
    justify-content: center;
    margin: 5px 0px;
    width: 100%;
    border-radius: 35px;
    color: white;
    background-color: #0a798d;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    z-index: 1000;
    text-transform: none;
    border: none;
    &:hover {
      background-color: #0a798d;
      transition-duration: 0s;

      &:checked {
        background-color: #00465a;
      }
    }
  }

  p {
    font-size: 15px;
    text-align: center;
    margin: 0 0px;
    margin-top: ${(props) => (props.isSmallScreen ? '5%' : '0%')};
    color: #333;
  }

  h2 {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
    color: #333;
  }
`;

export const Icon = styled.svg`
  display: inline-block; /* Torna o ícone um bloco de nível inline */
  vertical-align: middle; /* Alinha o ícone verticalmente ao centro */
  transform: scale(1.2);
  transition: 0.2s linear;

  & path {
    transition: 0.2s linear;
  }
`;
