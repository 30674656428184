import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Box, Grid, keyframes } from '@mui/material';
import TeamImage from '../../assets/img/Personagem.svg';

const slideUp = keyframes`
  0% {
    transform: translateY(20px); /* Mover 20px para baixo */
    opacity: 0; /* Iniciar transparente */
  }
  100% {
    transform: translateY(0); /* Retornar à posição original */
    opacity: 1; /* Tornar visível */
  }
`;

function AboutSection() {
  const [visibleIndexes, setVisibleIndexes] = useState([]);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleIndexes((prevVisibleIndexes) => [
              ...prevVisibleIndexes,
              parseInt(entry.target.getAttribute('data-index'), 10), // Especifica a base 10
            ]);
          }
        });
      },
      { threshold: 0.5 },
    );

    const elements = document.querySelectorAll('.about-item');
    elements.forEach((element) => {
      observer.current.observe(element);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <Box
      sx={{
        padding: '50px 0', // Padding
        textAlign: 'center',
        zIndex: 0,
      }}
    >
      <Container>
        <Typography id="quem-somos" variant="h3" gutterBottom style={{ fontFamily: 'Poppins', fontSize: '40px' }}>
          Quem Somos
        </Typography>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            className="about-item"
            data-index="0" // Adicionando data-index
            sx={{
              opacity: visibleIndexes.includes(0) ? 1 : 0,
              transform: visibleIndexes.includes(0) ? 'none' : 'translateY(20px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                animation: `${slideUp} 2.9s ease forwards`,
                textAlign: 'justify', // Justificando o texto
                margin: '8% 9%',
                fontFamily: 'Poppins',
                fontSize: '20px',
              }}
            >
              QuickMEI é um aplicativo desenvolvido especialmente para microempreendedores individuais (MEI), oferecendo
              todas as ferramentas necessárias para facilitar a gestão do seu negócio. Com ele, você poderá acompanhar
              suas obrigações fiscais e contábeis de forma simplificada, mantendo-se em dia com as demandas do seu
              empreendimento. O QuickMEI proporciona uma experiência personalizada, garantindo que cada MEI tenha o
              suporte adequado para gerenciar suas finanças e compromissos com eficiência e segurança. Tudo isso em um
              ambiente intuitivo, acessível e projetado para atender às necessidades de quem empreende sozinho.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="about-item"
            data-index="1" // Adicionando data-index
            sx={{
              opacity: visibleIndexes.includes(1) ? 1 : 0,
              transform: visibleIndexes.includes(1) ? 'none' : 'translateY(20px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <img
              src={TeamImage}
              alt="Equipe"
              style={{
                width: '80%',
                height: 'auto',
                margin: '10%',
                borderRadius: '10px', // Bordas arredondadas
                animation: `${slideUp} 2.9s ease forwards`,
                zIndex: 2,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AboutSection;
