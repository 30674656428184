import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FaEdit } from 'react-icons/fa';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, useMediaQuery } from '@mui/material';
import get from 'lodash/get';
import Modal from '@mui/material/Modal';
import Navbar from '../../components/NavBar';

import { Container, StyledContainer, Bloco, Icon } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';

export default function AdjustProduct() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleConfirmOpen = (productId) => {
    setDeleteId(productId);
    setConfirmOpen(true);
  };
  const handleConfirmClose = () => setConfirmOpen(false);

  const [isLoading, setIsLoading] = useState(false);
  const id = useSelector((state) => state.auth.user.id);
  const [products, setProducts] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const history = useHistory(); // Importa useHistory

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/product');
        const userProducts = response.data.filter((product) => product.userId === id);
        setProducts(userProducts);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, [id]);

  const handleDelete = async () => {
    if (deleteId === null) return;

    try {
      setIsLoading(true);
      await axios.delete(`/product/${deleteId}`);
      const newProducts = products.filter((product) => product.id !== deleteId);
      setProducts(newProducts);
      setIsLoading(false);
      toast.success('Produto excluído com sucesso');
    } catch (err) {
      const status = get(err, 'response.status', 0);

      if (status === 401) {
        toast.error('Você precisa fazer login');
      } else {
        toast.error('Ocorreu um erro ao excluir o produto');
      }

      setIsLoading(false);
    }

    handleConfirmClose();
  };

  const handleBack = () => {
    history.push('/product'); // Navega para a página /product
  };

  return (
    <div>
      <Navbar />
      <Container isSmallScreen={isSmallScreen}>
        <Loading isLoading={isLoading} />

        <StyledContainer isSmallScreen={isSmallScreen}>
          <h1 className="head">Ajustar Produtos</h1>
          <h4>Atualize informações de produtos existentes</h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontFamily: 'Poppins' }}>Nome</TableCell>
                <TableCell style={{ fontFamily: 'Poppins' }}>Editar</TableCell>
                <TableCell style={{ fontFamily: 'Poppins' }}>Excluir</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={String(product.id)}>
                  <TableCell data-label="Nome" style={{ fontFamily: 'Poppins' }}>
                    {product.nome}
                  </TableCell>
                  <TableCell data-label="Editar">
                    <Link to={`/edit-product/${product.id}`}>
                      <FaEdit color="black" size={16} />
                    </Link>
                  </TableCell>
                  <TableCell data-label="Excluir">
                    <Icon
                      viewBox="0 0 15 17.5"
                      height="17.5"
                      width="15"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleConfirmOpen(product.id)}
                    >
                      <path
                        transform="translate(-2.5 -1.25)"
                        d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                      />
                    </Icon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledContainer>

        <Modal open={confirmOpen} onClose={handleConfirmClose}>
          <Bloco>
            <div className="box-container">
              <h2>Confirmação</h2>
              <p>Tem certeza de que deseja deletar este produto?</p>
              <button type="button" onClick={handleDelete}>
                Sim
              </button>
              <button type="button" onClick={handleConfirmClose}>
                Não
              </button>
            </div>
          </Bloco>
        </Modal>
        <Button className="button" onClick={handleBack} variant="contained" color="primary">
          Voltar
        </Button>
      </Container>
    </div>
  );
}
