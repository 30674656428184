import React, { useState, useEffect } from 'react';
import { isEmail } from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import {
  useMediaQuery,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordChecklist from 'react-password-checklist';
import { ReactComponent as Logo } from '../../assets/img/Logo  (1).svg';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';
import { StyledContainer, Container, Composition } from './styled';

function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.auth.isLoading);

  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const [formStep, setFormStep] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const validarCPF = (cpfInput) => {
    const cpfValue = cpfInput.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cpfValue.length !== 11 || /^(\d)\1{10}$/.test(cpfValue)) {
      return false; // Verifica se o CPF tem 11 dígitos e se não é formado por dígitos repetidos
    }
    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i += 1) {
      soma += parseInt(cpfValue.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpfValue.substring(9, 10), 10)) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i += 1) {
      soma += parseInt(cpfValue.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    return resto === parseInt(cpfValue.substring(10, 11), 10);
  };

  const [formErrors, setFormErrors] = useState({
    nome: false,
    cnpj: false,
    telefone: false,
    cpf: false,
    email: false,
    password: false,
    passwordAgain: false,
    termsAccepted: false,
  });

  // Validar campos ao preencher
  useEffect(() => {
    const validateForm = () => {
      const newFormErrors = {
        nome: !(nome && nome.length >= 3 && nome.length <= 255),
        cnpj: !cnpj,
        telefone: !telefone,
        cpf: cpf && !validarCPF(cpf),
        email: !(email && isEmail(email)),
        password: !(password && isPasswordValid),
        passwordAgain: password !== passwordAgain,
        termsAccepted: !termsAccepted,
      };

      setFormErrors(newFormErrors);
    };

    validateForm();
  }, [nome, cnpj, telefone, cpf, email, password, passwordAgain, isPasswordValid, termsAccepted]);

  const handleCNPJChange = (e) => setCnpj(e.target.value);
  const handleTelefoneChange = (e) => setTelefone(e.target.value);
  const handleNomeChange = (e) => setNome(e.target.value);
  const handleTermsChange = (event) => setTermsAccepted(event.target.checked);
  const handleCpfChange = (e) => setCpf(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handlePasswordAgainChange = (e) => setPasswordAgain(e.target.value);

  const handleClickShowPassword = (field) => {
    if (field === 'password') setShowPassword(!showPassword);
    if (field === 'passwordAgain') setShowPasswordAgain(!showPasswordAgain);
  };

  const handleMouseDownPassword = (event) => event.preventDefault();
  const handlePasswordValidationChange = (isValid) => setIsPasswordValid(isValid);
  const handleNextStep = () => setFormStep(formStep + 1);
  const handlePrevStep = () => setFormStep(formStep - 1);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {
      nome: !(nome && nome.length >= 3 && nome.length <= 255),
      cnpj: !cnpj,
      telefone: !telefone,
      cpf: cpf && !validarCPF(cpf),
      email: !(email && isEmail(email)),
      password: !(password && isPasswordValid),
      passwordAgain: password !== passwordAgain,
      termsAccepted: !termsAccepted,
    };

    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      // Tratar erros de formulário aqui, se necessário
      return;
    }

    dispatch(actions.registerRequest({ nome, cpf, cnpj, email, telefone, password, history }));
  };

  return (
    <Container isSmallScreen={isSmallScreen}>
      <div className="box-container">
        <Loading isLoading={isLoading} />
        <StyledContainer>
          <Logo className="logo-box" />
          <form onSubmit={handleSubmit}>
            <h1>Crie uma nova conta</h1>
            {formStep === 1 && (
              <>
                <TextField
                  className="text-field"
                  InputProps={{ disableUnderline: true }}
                  label="Nome *"
                  value={nome}
                  onChange={handleNomeChange}
                  placeholder="Nome"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  className="text-field"
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: InputMask,
                    inputProps: {
                      mask: '99.999.999/9999-99',
                      value: cnpj,
                      maskChar: ' ',
                      name: 'cnpj',
                    },
                  }}
                  label="CNPJ *"
                  value={cnpj}
                  onChange={handleCNPJChange}
                  placeholder="Seu CNPJ"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  className="text-field"
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: InputMask,
                    inputProps: {
                      mask: '(99) 99999 9999',
                      value: telefone,
                      maskChar: ' ',
                      name: 'telefone',
                    },
                  }}
                  label="Telefone *"
                  value={telefone}
                  onChange={handleTelefoneChange}
                  placeholder="Seu Telefone"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  className="text-field"
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: InputMask,
                    inputProps: {
                      mask: '999.999.999-99',
                      value: cpf,
                      maskChar: ' ',
                      name: 'cpf',
                    },
                  }}
                  label="CPF *"
                  value={cpf}
                  onChange={handleCpfChange}
                  placeholder="Seu CPF"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={formErrors.cpf}
                  helperText={formErrors.cpf ? 'CPF inválido' : ''}
                />
                <TextField
                  className="text-field"
                  InputProps={{ disableUnderline: true }}
                  label="E-mail *"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Seu e-mail"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  className="text-field password"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end" className="eye">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          className="button-eye"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ className: 'input-label' }}
                  label="Senha"
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  variant="outlined"
                />
                <TextField
                  InputLabelProps={{ className: 'input-label' }}
                  label="Confirmar Senha"
                  className="text-field password"
                  id="standard-adornment-password-again"
                  type={showPasswordAgain ? 'text' : 'password'}
                  value={passwordAgain}
                  variant="outlined"
                  onChange={handlePasswordAgainChange}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end" className="eye">
                        <IconButton
                          className="button-eye"
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('passwordAgain')}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPasswordAgain ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordChecklist
                  className="PasswordChecklist"
                  rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                  minLength={8}
                  value={password}
                  valueAgain={passwordAgain}
                  onChange={handlePasswordValidationChange}
                  messages={{
                    minLength: 'A senha deve ter no mínimo 8 caracteres.',
                    specialChar: 'A senha deve conter pelo menos um caractere especial (ex: !, @, #, etc.).',
                    number: 'A senha deve conter pelo menos um número.',
                    capital: 'A senha deve conter pelo menos uma letra maiúscula.',
                    match: 'As senhas devem coincidir.',
                  }}
                  style={{ fontFamily: 'Poppins' }}
                />
                <FormControlLabel
                  style={{ fontFamily: 'Poppins' }}
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                      name="termsAccepted"
                      color="primary"
                      style={{ fontFamily: 'Poppins' }}
                    />
                  }
                  label={<span style={{ fontFamily: 'Poppins' }}>Aceito os Termos de Uso</span>}
                  error={formErrors.termsAccepted}
                  helperText={formErrors.termsAccepted ? 'Você precisa aceitar os termos de uso' : ''}
                />
                <Button
                  className="button"
                  type="button"
                  onClick={history.goBack}
                  variant="contained"
                  disableRipple
                  style={{ fontFamily: 'Poppins' }}
                >
                  Voltar
                </Button>
                <Button
                  className="button"
                  type="button"
                  onClick={handleNextStep}
                  variant="contained"
                  disableRipple
                  disabled={
                    formErrors.nome ||
                    formErrors.cnpj ||
                    formErrors.telefone ||
                    formErrors.cpf ||
                    formErrors.email ||
                    formErrors.password ||
                    formErrors.passwordAgain ||
                    formErrors.termsAccepted
                  }
                >
                  Próximo
                </Button>
              </>
            )}
            <Composition>
              {formStep === 2 && (
                <>
                  <p>Confirme dados</p>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Nome"
                    value={nome}
                    placeholder="Nome *"
                    disabled
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ boxShadow: 'none' }}
                  />
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="CNPJ *"
                    value={cnpj}
                    placeholder="Seu CNPJ"
                    disabled
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ boxShadow: 'none' }}
                  />
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Telefone *"
                    value={telefone}
                    placeholder="Seu Telefone"
                    disabled
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ boxShadow: 'none' }}
                  />
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="CPF *"
                    value={cpf}
                    placeholder="Seu CPF"
                    disabled
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ boxShadow: 'none' }}
                  />
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="E-mail *"
                    type="email"
                    value={email}
                    placeholder="Seu e-mail"
                    disabled
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    style={{ boxShadow: 'none' }}
                  />
                  <PasswordChecklist
                    className="PasswordChecklist"
                    rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                    minLength={8}
                    disabled
                    value={password}
                    valueAgain={passwordAgain}
                    onChange={handlePasswordValidationChange}
                    messages={{
                      minLength: 'A senha deve ter no mínimo 8 caracteres.',
                      specialChar: 'A senha deve conter pelo menos um caractere especial (ex: !, @, #, etc.).',
                      number: 'A senha deve conter pelo menos um número.',
                      capital: 'A senha deve conter pelo menos uma letra maiúscula.',
                      match: 'As senhas devem coincidir.',
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={termsAccepted}
                        onChange={handleTermsChange}
                        name="termsAccepted"
                        color="primary"
                      />
                    }
                    label="Aceito os termos de uso"
                    error={formErrors.termsAccepted}
                    helperText={formErrors.termsAccepted ? 'Você precisa aceitar os termos de uso' : ''}
                  />
                  <Button
                    className="button"
                    type="button"
                    onClick={handlePrevStep}
                    variant="contained"
                    disableRipple
                    style={{ marginRight: isSmallScreen ? '8px' : '16px', marginBottom: '10px' }}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableRipple
                    style={{ marginBottom: '50px' }}
                  >
                    Finalizar
                  </Button>
                </>
              )}
            </Composition>
          </form>
        </StyledContainer>
      </div>
    </Container>
  );
}

export default Register;
