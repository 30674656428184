import React from 'react';
import HeroSection from '../../components/landing/HeroSection';
import FeaturesSection from '../../components/landing/FeaturesSection';
import Footer from '../../components/landing/Footer';
import AppBar from '../../components/landing/AppBar';
import AboutSection from '../../components/landing/AboutSection';
import Faq from '../../components/landing/faq';

function LandingPage() {
  return (
    <div>
      <AppBar />
      <HeroSection />
      <AboutSection />
      <FeaturesSection />
      <Faq />
      <Footer />
    </div>
  );
}

export default LandingPage;
