import styled from 'styled-components';

export const Form = styled.form`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  overflow: hidden;
  @media (max-width: 2560px) {
    max-height: 100%;
  }

  @media (max-width: 882px) {
    max-width: 70%;
  }

  @media (max-width: 385px) {
    max-width: 100%;
    max-height: 150%;
  }

  @media (max-width: 1450px) {
    max-width: 80%;
    max-height: 180%;
  }

  h1 {
    margin: 8% 0px;
    font-size: 20px;
    @media screen and (max-width: 767px) {
      top: -50%; /* A altura será igual a largura em telas menores */
    }
  }

  label {
    display: flex;
    font-family: 'Poppins', sans-serif;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .button {
    margin-top: 15px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    margin-bottom: 5%;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
    @media (max-width: 385px) {
      max-width: 100%;
      height: 50%;
    }
  }

  .Button {
    margin: 0px 5px;
    height: 40px;
    margin-top: -20%;
    border-radius: 35px;
    width: 258px;
    color: white;
    background-color: #0a798d;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    text-transform: none;

    &:hover {
      background-color: #00465a;
      transition-duration: 0s;
    }

    &:checked {
      background-color: #00465a;
    }

    @media screen and (max-width: 767px) {
      width: 120px; /* Metade do tamanho original */
      margin-top: -20%;
    }
  }

  .text-field {
    font-family: 'Poppins', sans-serif;

    & label.Mui-focused {
      color: #0069d9;
    }

    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #0069d9;
      }
    }
  }

  .fixed-height .MuiOutlinedInput-root {
    height: auto;
    border: none;
  }

  .fixed-height .MuiOutlinedInput-multiline {
    padding: 5px;
    border: none;
  }

  .fixed-height .MuiInputBase-inputMultiline {
    height: 100px;
    border: none;
  }

  input {
    width: 100%;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    border-radius: 4px;
    border: none;
  }

  .option-label {
    margin-top: -10px;
    font-family: 'Poppins', sans-serif;
    width: 550px;
  }

  .MuiFormControl-root {
    width: 100%;
    height: 56px; /* Define uma altura fixa */
  }

  .MuiSelect-root {
    height: 40px; /* Ajuste para manter o tamanho do Select fixo */
    display: flex;
    align-items: center;
  }

  .MuiInputLabel-root {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }

  .MuiInputBase-root {
    height: 40px; /* Define altura fixa para o campo de texto */
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 868px) {
    width: 580px;
    h1 {
      margin-top: 10%;
    }
  }

  @media screen and (max-width: 867px) {
    width: calc(100% - 40px);
    margin-top: 10px;

    h1 {
      margin-top: 5%;
    }
  }
`;
