import styled from 'styled-components';

export const Composition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7%;

  .logo-box {
    width: 70%;
    max-width: 470px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    margin-bottom: ${(props) => (props.isSmallScreen ? '-5%' : '-5%')};
    margin-top: ${(props) => (props.isSmallScreen ? '-30%' : '-35%')};
  }

  width: ${(props) => (props.isSmallScreen ? 'calc(100% - 0px)' : '110%')}; /* Ajuste para telas pequenas */
  height: ${(props) => (props.isSmallScreen ? '100%' : '90%')};
  max-width: ${(props) => (props.isSmallScreen ? 'none' : '500px')};
  padding: ${(props) => (props.isSmallScreen ? '0 10px' : '30px')}; /* Adicione padding horizontal em telas pequenas */
  border: 1px solid #ccc;
  border-radius: ${(props) => (props.isSmallScreen ? '0' : '10px')};
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: -10px;
  margin-top: ${(props) => (props.isSmallScreen ? '-5%' : '2%')};
  box-sizing: border-box;
  position: ${(props) => (props.isSmallScreen ? 'absolute' : 'static')};
  top: ${(props) => (props.isSmallScreen ? '0' : 'auto')};
  left: ${(props) => (props.isSmallScreen ? '5' : 'auto')};
  transform: ${(props) => (props.isSmallScreen ? 'none' : 'none')};

  @media (max-width: 768px) {
    /* Adicione margem lateral para a box-container em telas pequenas */
    padding: 0 20px; /* Adicione padding horizontal em telas pequenas */
    margin: 0;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
    margin-top: ${(props) => (props.isSmallScreen ? '-60%' : '-40%')};
    color: #333;
  }

  p {
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
    margin-top: 5%;
  }

  .text-field {
    width: 100%;
    margin-bottom: ${(props) => (props.isSmallScreen ? '15px' : '5px')};
    margin-top: 5%;
    font-family: 'Poppins', sans-serif;

    & label.Mui-focused {
      color: blue;
    }

    & .MuiOutlinedInput-root {
      border-radius: 35px;

      &.Mui-focused fieldset {
        border-color: blue;
      }
    }
  }

  .button {
    margin-top: 15px;
    width: 100%;
    height: ${(props) => (props.isSmallScreen ? '45px' : '50px')};
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }
  .PasswordChecklist {
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: 'Poppins', sans-serif;
  }
`;

export const Form = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0;
`;

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0;
`;
