import React, { useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import { TextField, useMediaQuery } from '@mui/material';
import * as actions from '../../store/modules/auth/actions';
import { ReactComponent as Logo } from '../../assets/img/Logo  (1).svg';

import { Composition, Container } from './styled';
import Loading from '../../components/Loading';

function PasswordResetPage() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const location = useLocation();
  const isRecovery = location.pathname === '/recuperar-senha';
  const history = useHistory();

  const [email, setEmail] = useState('');

  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isEmail(email)) {
      toast.error('E-mail inválido.');
      return;
    }
    console.log('rodei');
    dispatch(actions.passwordResetRequest({ history, email, endpoint: '/users/recover' }));
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Composition isSmallScreen={isSmallScreen}>
        <div className="box-container">
          <Logo className="logo-box" />
          <h1>{isRecovery ? 'Recuperação de Senha' : 'Redefinição de Senha'}</h1>
          <form onSubmit={handleSubmit}>
            {' '}
            {/* Adicione a propriedade onSubmit aqui */}
            <TextField
              className="text-field"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Seu e-mail"
            />
            <div className="button-container">
              <Link className="link" to="/login">
                <button className="button" type="button">
                  Voltar
                </button>
              </Link>
              <button className="button" type="submit" disabled={isLoading}>
                {isRecovery ? 'Próximo' : 'Redefinir Senha'}
              </button>
            </div>
          </form>
        </div>
      </Composition>
    </Container>
  );
}

export default PasswordResetPage;
