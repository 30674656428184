import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import * as actions from './actions';
import * as types from '../types';
import axios from '../../../services/axios';

function* confirmRequest({ payload }) {
  try {
    const { tokenWithoutColon, history } = payload;
    yield call(axios.post, `/users/confirm/?${tokenWithoutColon}`);
    toast.success('Conta Validada.');
    yield put(
      actions.confirmAccountSuccess({
        tokenWithoutColon,
        history,
      }),
    );
    yield payload.history.push('/');
  } catch (error) {
    toast.error('Conta não validada.');
    yield put(actions.confirmAccountFailure());
  }
}

function* loginRequest({ payload }) {
  try {
    const response = yield call(axios.post, '/tokens', payload);

    const { token, user } = response.data;

    yield put(actions.setDepartment(user.departamento));

    axios.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(actions.loginSuccess({ ...response.data }));

    switch (user.departamento) {
      case 'Admin':
        yield payload.history.push('/home-admin');
        break;
      case 'Empresa':
        yield payload.history.push('/home-empresa');
        break;
      default:
        yield payload.history.push('/home');
        break;
    }
  } catch (e) {
    // Adicione esta linha para verificar a resposta completa

    if (
      e.response &&
      e.response.status === 402 &&
      e.response.data.errors[0] === 'Conta bloqueada. Entre em contato com o suporte.'
    ) {
      toast.error('Conta bloqueada. Entre em contato com o suporte.');
    } else if (
      e.response &&
      e.response.status === 401 &&
      e.response.data.errors[0] === 'Conta não validada. Faça a validação do e-mail.'
    ) {
      toast.error('Conta não validada. Faça a validação do e-mail.');
    } else {
      toast.error('Usuário ou senha inválidos.');
    }

    yield put(actions.loginFailure());
  }
}
function* passwordResetRequest({ payload }) {
  try {
    const { email } = payload;

    yield call(axios.post, '/users/recover-password', { email });

    toast.success('Um e-mail de recuperação de senha foi enviado.');

    yield put(actions.passwordResetSuccess());
    yield payload.history.push('/');
  } catch (error) {
    console.error('Erro ao solicitar recuperação de senha:', error);

    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    toast.error('Ocorreu um erro ao solicitar a recuperação de senha.');
    yield put(actions.passwordResetFailure());
  }
}

function* passwordRecoveryRequest({ payload }) {
  try {
    const { token, newPassword } = payload;

    yield call(axios.post, `/users/reset-password?token=${token}`, { newPassword });

    toast.success('Senha redefinida com sucesso.');

    yield put(actions.passwordRecoverySuccess());
    yield payload.history.push('/');
  } catch (error) {
    console.error('Erro ao redefinir senha:', error);

    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    toast.error('Ocorreu um erro ao redefinir a senha.');
    yield put(actions.passwordRecoveryFailure());
  }
}

function persistRehydrate({ payload }) {
  const token = get(payload, 'auth.token', '');
  if (!token) return;
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

// eslint-disable-next-line consistent-return
function* registerRequest({ payload }) {
  const { cnpj, cpf, telefone, nome, email, password, history } = payload;

  try {
    const dataToSend = {
      cnpj,
      history,
      cpf,
      telefone,
      nome,
      email,
      password,
    };

    if (telefone !== undefined && telefone !== null && telefone !== '') {
      dataToSend.telefone = telefone;
    } else {
      dataToSend.telefone = null;
    }

    yield call(axios.post, '/users', dataToSend);
    toast.success('Conta criada com sucesso!');
    yield put(
      actions.registerCreatedSuccess({
        cnpj,
        cpf,
        telefone,
        history,
        nome,
        email,
        password,
      }),
    );
    yield payload.history.push('/');
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    }

    yield put(actions.registerFailure());
  }
}

function* registerProductRequest({ payload }) {
  const { nome, preco, estoque, history, unidade, custo, codBarras, descricao, categoria, NCM, userId } = payload;
  try {
    const dataToSend = {
      nome,
      preco,
      estoque,
      unidade,
      custo,
      codBarras,
      descricao,
      categoria,
      NCM,
      userId,
    };

    console.log(dataToSend);

    yield call(axios.post, '/product', dataToSend);
    toast.success('Produto adicionado com sucesso!');
    yield put(
      actions.registerProductSuccess({
        nome,
        preco,
        estoque,
        history,
        unidade,
        custo,
        codBarras,
        descricao,
        categoria,
        NCM,
        userId,
      }),
    );
    yield payload.history.push(`/home`);
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerProductFailure());
  }
}

function* editProductRequest({ payload }) {
  const { nome, preco, estoque, history, unidade, custo, codBarras, descricao, categoria, NCM, id } = payload;
  try {
    const dataToSend = {
      nome,
      preco,
      estoque,
      unidade,
      history,
      custo,
      codBarras,
      descricao,
      categoria,
      NCM,
      id,
    };

    yield call(axios.put, `/product/${id}`, dataToSend);
    toast.success('Produto modificado com sucesso!');
    yield put(
      actions.editProductSuccess({
        nome,
        preco,
        estoque,
        history,
        unidade,
        custo,
        codBarras,
        descricao,
        categoria,
        NCM,
        id,
      }),
    );
    yield payload.history.push(`/adjust-product`);
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editProductFailure());
  }
}

function* editRequest({ payload }) {
  const {
    id,
    nome,
    cnpj,
    nome_fantasia,
    responsavel_empresa,
    razao_social,
    telefone,
    cep,
    endereco,
    numero,
    bairro,
    complemento,
    cidade,
    estado,
    date_start,
    date_end,
    sobrenome,
    cargo,
    departamento,
    email,
    password,
  } = payload;

  try {
    const userData = {
      email,
      nome,
      sobrenome,
      cargo,
      departamento,
      cnpj,
      nome_fantasia,
      responsavel_empresa,
      razao_social,
      telefone,
      cep,
      endereco,
      numero,
      bairro,
      complemento,
      cidade,
      estado,
      date_start,
      date_end,
    };
    if (telefone !== undefined && telefone !== null && telefone !== '') {
      userData.telefone = telefone;
    } else {
      userData.telefone = null;
    }
    if (numero !== undefined && numero !== null && numero !== '') {
      userData.numero = numero;
    } else {
      userData.numero = '000';
    }
    if (password) {
      userData.password = password;
    }

    yield call(axios.put, `/rhusers/${id}`, userData);
    toast.success('Conta Editada com sucesso!');
    yield put(
      actions.editSuccess({
        id,
        nome,
        sobrenome,
        cargo,
        departamento,
        cnpj,
        nome_fantasia,
        responsavel_empresa,
        razao_social,
        telefone,
        cep,
        endereco,
        numero,
        bairro,
        complemento,
        cidade,
        estado,
        date_start,
        date_end,
        email,
        password,
      }),
    );
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editFailure());
  }
}

function* RegisterClientRequest({ payload }) {
  const {
    nome,
    history,
    userId,
    documento,
    telefone,
    email,
    inscricao,
    cep,
    cidade,
    logradouro,
    numero,
    UF,
    bairro,
    complemento,
    emissao,
  } = payload;
  try {
    const userData = {
      nome,
      documento,
      telefone,
      userId,
      history,
      email,
      inscricao,
      cep,
      cidade,
      logradouro,
      numero,
      UF,
      bairro,
      complemento,
      emissao,
    };

    yield call(axios.post, `/client/`, userData);
    toast.success('Cliente cadastrado com sucesso!');
    yield put(
      actions.registerClientSuccess({
        nome,
        documento,
        telefone,
        userId,
        email,
        inscricao,
        cep,
        cidade,
        logradouro,
        history,
        numero,
        UF,
        bairro,
        complemento,
        emissao,
      }),
    );
    yield payload.history.push(`/home`);
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerClientFailure());
  }
}

function* editClientRequest({ payload }) {
  const {
    nome,
    history,
    id,
    documento,
    telefone,
    email,
    inscricao,
    cep,
    cidade,
    logradouro,
    numero,
    UF,
    bairro,
    complemento,
    emissao,
  } = payload;
  try {
    const userData = {
      nome,
      documento,
      telefone,
      id,
      history,
      email,
      inscricao,
      cep,
      cidade,
      logradouro,
      numero,
      UF,
      bairro,
      complemento,
      emissao,
    };

    yield call(axios.put, `/client/${id}`, userData);
    toast.success('Cliente ajustado com sucesso!');
    yield put(
      actions.editClientSuccess({
        nome,
        documento,
        telefone,
        id,
        email,
        inscricao,
        cep,
        cidade,
        logradouro,
        history,
        numero,
        UF,
        bairro,
        complemento,
        emissao,
      }),
    );
    yield payload.history.push(`/client`);
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editClientFailure());
  }
}

export default all([
  takeLatest(types.EDIT_CLIENT_REQUEST, editClientRequest),

  takeLatest(types.EDIT_PRODUCT_REQUEST, editProductRequest),
  takeLatest(types.REGISTER_CLIENT_REQUEST, RegisterClientRequest),
  takeLatest(types.CONFIRM_ACCOUNT_REQUEST, confirmRequest),
  takeLatest(types.LOGIN_REQUEST, loginRequest),
  takeLatest(types.PERSIST_REHYDRATE, persistRehydrate),
  takeLatest(types.REGISTER_REQUEST, registerRequest),

  takeLatest(types.REGISTER_PRODUCT_REQUEST, registerProductRequest),

  takeLatest(types.EDIT_REQUEST, editRequest),
  takeLatest(types.PASSWORD_RESET_REQUEST, passwordResetRequest),
  takeLatest(types.PASSWORD_RECOVERY_REQUEST, passwordRecoveryRequest),
]);
