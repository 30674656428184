import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'O que é o QuickMEI?',
    answer:
      'O QuickMEI é um aplicativo desenvolvido para ajudar microempreendedores a gerenciar seus negócios de forma prática e eficiente, com recursos integrados que facilitam a administração financeira, fiscal e de clientes.',
  },
  {
    question: 'Quais são os principais recursos do QuickMEI?',
    answer:
      'O QuickMEI oferece funcionalidades como gestão de DAS, gerenciamento de notas fiscais, controle de clientes, administração financeira e suporte integrado, tudo pensado para simplificar a rotina do microempreendedor.',
  },
  {
    question: 'O QuickMEI é seguro?',
    answer:
      'Sim! A segurança dos dados dos nossos usuários é uma prioridade. Utilizamos as melhores práticas de segurança da informação para garantir que suas informações estejam sempre protegidas.',
  },
  {
    question: 'É fácil usar o QuickMEI?',
    answer:
      'Sim! O QuickMEI possui uma interface amigável e intuitiva, permitindo que mesmo aqueles com pouco conhecimento técnico possam utilizá-lo sem dificuldades.',
  },
  {
    question: 'Como posso obter suporte se tiver dúvidas?',
    answer:
      'Oferecemos suporte integrado dentro do aplicativo, além de canais de atendimento ao cliente para resolver qualquer questão que possa surgir.',
  },
];

function FaqSection() {
  const [visibleIndexes, setVisibleIndexes] = useState([]);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleIndexes((prevVisibleIndexes) => [
              ...prevVisibleIndexes,
              parseInt(entry.target.getAttribute('data-index'), 10), // Especifica a base 10
            ]);
          }
        });
      },
      { threshold: 0.3 },
    );

    const elements = document.querySelectorAll('.faq-item');
    elements.forEach((element) => {
      observer.current.observe(element);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <Box
      id="faq"
      sx={{
        background: 'linear-gradient(135deg, #fff, #fff)',
        padding: '5% 0',
        textAlign: 'center',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Container>
        <Typography
          id="faq"
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: '#333', marginBottom: '5%', fontFamily: 'Poppins', fontSize: '40px' }}
        >
          Perguntas Frequentes
        </Typography>

        {faqs.map((faq, index) => (
          <Accordion
            key={faq.question}
            className="faq-item"
            data-index={index} // Adicionando data-index
            sx={{
              marginBottom: '15px',
              zIndex: '1',
              opacity: visibleIndexes.includes(index) ? 1 : 0,
              transform: visibleIndexes.includes(index) ? 'none' : 'translateY(20px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                background: 'linear-gradient(135deg, #00d2ff, #6fb1fc, #4364f7, #0052d4, #0a798d)',
                transition: 'background 0.3s ease', // Suave transição na cor
                '&:hover': {
                  background: 'linear-gradient(135deg, #4364f7, #0052d4, #0a798d)', // Cor alterada no hover
                  cursor: 'pointer', // Muda o cursor para pointer
                },
              }}
            >
              <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: 'linear-gradient(135deg, #00d2ff, #6fb1fc, #6fb1fc, #00d2ff)' }}>
              <Typography sx={{ fontFamily: 'Poppins' }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        {/* Linha azul no final */}
        <Box
          sx={{
            width: '120%',
            height: '5px', // Espessura da linha
            background: 'linear-gradient(135deg, #0a798d, #0052d4, #4364f7, #6fb1fc, #00d2ff, #00d2ff )', // Cor da linha
            marginTop: '100px',
            marginLeft: '-10%',
          }}
        />
      </Container>
    </Box>
  );
}

export default FaqSection;
