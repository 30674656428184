import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home'; // Ícone para Home
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Ícone para Sair
import { ReactComponent as Logo } from '../../assets/img/Design sem nome (8).svg'; // Corrigir caminho do logo
import * as actions from '../../store/modules/auth/actions';

const NavbarContainer = styled.nav`
  background-color: #ced4da;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 15%;
  z-index: 1000;
`;

const MenuButton = styled(IconButton)`
  color: white;
  position: absolute;
  right: 50px;
  top: 5%;
`;

const StyledMenuIcon = styled(MenuIcon)`
  font-size: 3rem !important; /* Ajuste o tamanho do ícone conforme necessário */
  color: #0a798d; /* Define a cor azul */
`;

const ResponsiveLogo = styled(Logo)`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const StyledListItem = styled(ListItem)`
  &:hover {
    background-color: #0a798d !important; /* Cor de fundo do item ao passar o mouse */
    color: white;
    width: 470px;
    color: black;
    border: 1px solid black;
    border-radius: 5px;

    & .MuiListItemText-primary {
      color: white;
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    top: 15%; /* Inicia o Drawer abaixo da Navbar */
    height: calc(100% - 15%); /* Altura total menos a altura da Navbar */
    z-index: 999; /* z-index menor que o da Navbar */
    position: fixed;
  }
`;

function Navbar({ onLogout }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const isXLarge = useMediaQuery('(min-width:1600px)');
  const isLarge = useMediaQuery('(min-width:1200px) and (max-width:1599px)');
  const isMedium = useMediaQuery('(min-width:992px) and (max-width:1199px)');
  const isSmall = useMediaQuery('(min-width:768px) and (max-width:991px)');

  // Função para calcular a largura do drawer com base no tamanho da tela
  const getDrawerWidth = () => {
    if (isXLarge) return '400px';
    if (isLarge) return '350px';
    if (isMedium) return '300px';
    if (isSmall) return '250px';
    return '200px'; // Valor padrão para telas menores
  };

  // Função para calcular o tamanho do logo com base no tamanho da tela
  const getLogoSize = () => {
    if (isXLarge) return 200;
    if (isLarge) return 280;
    if (isMedium) return 250;
    if (isSmall) return 220;
    return 200; // Valor padrão para telas menores
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actions.loginFailure());
    history.push('/');
  };

  return (
    <NavbarContainer>
      <ResponsiveLogo size={getLogoSize()} />
      <MenuButton edge="end" aria-label="menu" onClick={toggleDrawer(true)}>
        <StyledMenuIcon />
      </MenuButton>
      <StyledDrawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ '& .MuiDrawer-paper': { width: getDrawerWidth() } }} // Define a largura do drawer
      >
        <List>
          <StyledListItem button component={Link} to="/home">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" tyle={{ fontFamily: 'Poppins' }} />
          </StyledListItem>
          <StyledListItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" onClick={onLogout} tyle={{ fontFamily: 'Poppins' }} />
          </StyledListItem>
        </List>
      </StyledDrawer>
    </NavbarContainer>
  );
}

Navbar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default Navbar;
