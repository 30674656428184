import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Typography, Paper, Box } from '@mui/material';

const features = [
  {
    title: 'Gestão de DAS',
    description:
      'Controle o pagamento do DAS de forma prática, receba notificações sobre prazos e gere suas guias diretamente no app, garantindo que tudo esteja sempre em dia.',
  },
  {
    title: 'Gestão de NFs',
    description:
      'Emita, organize e visualize suas notas fiscais eletrônicas com facilidade, tudo dentro do app, agilizando o processo e mantendo tudo em um só lugar.',
  },
  {
    title: 'Gestão de Clientes',
    description:
      'Gerencie as informações dos seus clientes de maneira eficiente, facilitando o controle de contatos e garantindo um melhor atendimento em suas transações.',
  },
  {
    title: 'Gestão Financeira',
    description:
      'Monitore suas receitas, despesas e o fluxo de caixa em tempo real, mantendo um controle financeiro preciso e acessível a qualquer momento.',
  },
  {
    title: 'Suporte Integrado',
    description:
      'Conte com um suporte especializado dentro do app para esclarecer dúvidas e resolver questões rapidamente, sempre que precisar de ajuda.',
  },
];

function FeaturesSection() {
  const [visibleIndexes, setVisibleIndexes] = useState([]);

  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleIndexes((prevVisibleIndexes) => [
              ...prevVisibleIndexes,
              parseInt(entry.target.getAttribute('data-index'), 10), // Especifica a base 10
            ]);
          }
        });
      },
      { threshold: 0.9 },
    );

    const elements = document.querySelectorAll('.feature-card');
    elements.forEach((element) => {
      observer.current.observe(element);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #0a798d, #0052d4, #4364f7, #6fb1fc, #00d2ff)',
        padding: '10% 0',
        textAlign: 'center',
        clipPath: {
          xs: 'none', // Sem clipPath em telas pequenas
          md: 'polygon(0 0, 100% 0%, 100% 85%, 0 100%)', // Aplicar o clipPath em telas médias ou maiores
        },
        position: 'relative',
        zIndex: 2,
      }}
    >
      <Container>
        <Typography
          id="soluções"
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: 'white', marginBottom: '5%', fontFamily: 'Poppins', fontSize: '40px' }}
        >
          Soluções
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={feature.title}
              className="feature-card"
              data-index={index}
              sx={{
                opacity: visibleIndexes.includes(index) ? 1 : 0,
                transform: visibleIndexes.includes(index) ? 'none' : 'translateY(20px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
              }}
            >
              <Paper
                elevation={3}
                style={{
                  marginBottom: '15%',
                  padding: '30px',
                  textAlign: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}
              >
                <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>
                  {feature.title}
                </Typography>
                <Typography sx={{ fontFamily: 'Poppins' }}>{feature.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default FeaturesSection;
