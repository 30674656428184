import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const getWidth = (props) => {
  if (props.isSmallScreen) return 'calc(100% - 10px)';
  if (props.isTabletScreen) return '100%';
  return '110%';
};

const getHeight = (props) => {
  if (props.isSmallScreen) return '170%';
  if (props.isTabletScreen) return '250%';
  return '151%';
};

const getPadding = (props) => {
  if (props.isSmallScreen) return '30 0px';
  if (props.isTabletScreen) return '30px 0px';
  return '30px';
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .box-container {
    width: ${(props) => getWidth(props)};
    height: ${(props) => getHeight(props)};
    max-width: ${(props) => (props.isSmallScreen ? '100vh' : '500px')};
    padding: ${(props) => getPadding(props)};
    border: 1px solid #ccc;
    border-radius: ${(props) => (props.isSmallScreen ? '0' : '10px')};
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: -10px;
    margin-top: ${(props) => (props.isSmallScreen ? '5%' : '22%')};
    box-sizing: border-box;
    position: ${(props) => (props.isSmallScreen ? 'absolute' : 'static')};
    top: ${(props) => (props.isSmallScreen ? '0' : 'auto')};
    left: ${(props) => (props.isSmallScreen ? '5' : 'auto')};
    transform: ${(props) => (props.isSmallScreen ? 'none' : 'none')};

    @media (max-width: 798px) {
      padding: 0 20px;
      margin: 0px;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      width: 100%;
      height: 100%;
      padding: 10px 20px;
      margin: 0;
    }

    @media ${breakpoints.xl} {
      height: auto;
    }

    @media ${breakpoints.lg} {
      height: auto;
    }

    @media ${breakpoints.xm} {
      height: auto;
    }
  }
`;

export const Composition = styled.div`
  max-height: 50%;
  .logo-box {
    width: 50%;
    max-width: 470px;
    object-fit: contain;
    display: flex;
    text-align: center;
    margin-top: -30%;
    margin-left: 22%;
    justify-content: center;

    @media ${breakpoints.xm} {
      margin-top: -50%;
    }
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-top: -40%;
    margin-bottom: ${(props) => (props.isSmallScreen ? '0px' : '5px')};
    color: #333;

    @media ${breakpoints.xm} {
      margin-top: -60%;
    }
  }

  .text-field {
    width: 100%;
    margin-top: 5%;
    font-family: 'Poppins', sans-serif;

    & label.Mui-focused {
      color: blue;
    }

    & .MuiOutlinedInput-root {
      border-radius: 35px;

      &.Mui-focused fieldset {
        border-color: blue;
      }
    }
  }
  p {
    font-size: 20px;
    text-align: center;
    margin-bottom: ${(props) => (props.isSmallScreen ? '0px' : '5px')};
    color: #333;
  }

  .password {
    margin-bottom: 5px;
  }

  .eye {
    margin-right: 8px;
  }

  .button {
    margin-top: 15px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }

  .PasswordChecklist {
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: 'Poppins', sans-serif;
  }
`;
export const StyledContainer = styled.div`
  .logo-box {
    width: 50%;
    max-width: 470px;
    object-fit: contain;
    display: flex;
    text-align: center;
    margin-top: -30%;
    margin-left: 22%;
    justify-content: center;

    @media ${breakpoints.xm} {
      margin-top: -50%;
    }
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-top: -40%;
    font-family: 'Poppins', sans-serif;
    margin-bottom: ${(props) => (props.isSmallScreen ? '0px' : '5px')};
    color: #333;

    @media ${breakpoints.xm} {
      margin-top: -60%;
    }
  }

  .text-field {
    width: 100%;
    margin-top: 5%;
    font-family: 'Poppins', sans-serif;

    & label.Mui-focused {
      color: blue;
    }

    & .MuiOutlinedInput-root {
      border-radius: 35px;

      &.Mui-focused fieldset {
        border-color: blue;
      }
    }
  }
  p {
    font-size: 20px;
    text-align: center;
    margin-bottom: ${(props) => (props.isSmallScreen ? '0px' : '5px')};
    color: #333;
  }

  .password {
    margin-bottom: 5px;
  }

  .eye {
    margin-right: 8px;
  }

  .button {
    margin-top: 15px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }

  .PasswordChecklist {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;
