import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Button, TextField, useMediaQuery, Grid, MenuItem, InputLabel } from '@mui/material';
import Layout from '../../components/Bug/Layout';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';
import { StyledContainer, Composition, Container } from './styled';

export default function Register() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const id = useSelector((state) => state.auth.user.id);
  const history = useHistory();

  const [formStep, setFormStep] = useState(1);

  const [nome, setNome] = useState('');
  const [preco, setPreco] = useState();
  const [estoque, setEstoque] = useState('');
  const [unidade, setUnidade] = useState('');
  const [custo, setCusto] = useState();
  const [codBarras, setCodBarras] = useState('');
  const [descricao, setDescricao] = useState('');
  const [categoria, setCategoria] = useState('');
  const [NCM, setNCM] = useState('');

  const [formErrors, setFormErrors] = useState({
    nome: '',
    preco: '',
    estoque: '',
    unidade: '',
  });

  // Use media query to detect screen size
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleNomeChange = (e) => {
    setNome(e.target.value);
  };

  const handlePrecoChange = (e) => {
    setPreco(e.target.value);
  };

  const handleEstoqueChange = (e) => {
    setEstoque(e.target.value);
  };

  const handleUnidadeChange = (e) => {
    setUnidade(e.target.value);
  };

  const handleCustoChange = (e) => {
    setCusto(e.target.value);
  };

  const handleCodBarrasChange = (e) => {
    setCodBarras(e.target.value);
  };

  const handleDescricaoChange = (e) => {
    setDescricao(e.target.value);
  };

  const handleCategoriaChange = (e) => {
    setCategoria(e.target.value);
  };

  const handleNCMChange = (e) => {
    setNCM(e.target.value);
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {};

    if (!nome) {
      newFormErrors.nome = 'Nome do produto é obrigatório';
    } else {
      newFormErrors.nome = '';
    }

    if (!preco) {
      newFormErrors.preco = 'Preço do produto é obrigatório';
    } else {
      newFormErrors.preco = '';
    }

    if (!estoque) {
      newFormErrors.estoque = 'Quantidade em estoque é obrigatória';
    } else {
      newFormErrors.estoque = '';
    }

    if (!unidade) {
      newFormErrors.unidade = 'Unidade do produto é obrigatória';
    } else {
      newFormErrors.unidade = '';
    }

    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      return; // Se houver erros, não prosseguir com o submit
    }

    const convertedPreco =
      preco && !Number.isNaN(Number(preco.replace(/[^0-9,.-]/g, '').replace(',', '.')))
        ? parseFloat(preco.replace(/[^0-9,.-]/g, '').replace(',', '.'))
        : 0;
    const convertedCusto =
      custo && !Number.isNaN(Number(custo.replace(/[^0-9,.-]/g, '').replace(',', '.')))
        ? parseFloat(custo.replace(/[^0-9,.-]/g, '').replace(',', '.'))
        : 0;

    dispatch(
      actions.registerProductRequest({
        nome,
        preco: convertedPreco,
        estoque,
        unidade,
        custo: convertedCusto,
        codBarras,
        history,
        descricao,
        categoria,
        NCM,
        userId: id,
      }),
    );
  };

  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // Quantidade de casas decimais permitidas
    integerLimit: 7, // Quantidade máxima de dígitos permitidos no número inteiro
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  return (
    <Layout>
      <Container>
        <Loading isLoading={isLoading} />
        <StyledContainer>
          <form onSubmit={handleSubmit}>
            <h1>Cadastre um novo produto</h1>
            {formStep === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    label="Nome do produto *"
                    value={nome}
                    onChange={handleNomeChange}
                    placeholder="Nome do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={!!formErrors.nome}
                    helperText={formErrors.nome}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MaskedInput
                    mask={currencyMask}
                    value={preco}
                    onChange={handlePrecoChange}
                    render={(ref, props) => (
                      <TextField
                        className="text-field"
                        InputProps={{ inputRef: ref, disableUnderline: true }}
                        label="Preço de Venda*"
                        placeholder="Preço de venda"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={!!formErrors.preco}
                        helperText={formErrors.preco}
                        {...props}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Qtd. Estoque *"
                    value={estoque}
                    onChange={handleEstoqueChange}
                    placeholder="Quantidade em estoque"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={!!formErrors.estoque}
                    helperText={formErrors.estoque}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Unidade (kg, Un, Metros,Pacote ) *</InputLabel>
                  <TextField
                    select
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    value={unidade}
                    onChange={handleUnidadeChange}
                    variant="outlined"
                    placeholder="Escolha uma unidade"
                    error={!!formErrors.unidade}
                    helperText={formErrors.unidade}
                  >
                    <MenuItem value="Kg">Kg</MenuItem>
                    <MenuItem value="Un">Unidade</MenuItem>
                    <MenuItem value="Metros">Metros</MenuItem>
                    <MenuItem value="Pacote">Pacote</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} sm={6}>
                  <MaskedInput
                    mask={currencyMask}
                    value={custo}
                    onChange={handleCustoChange}
                    render={(ref, props) => (
                      <TextField
                        className="text-field"
                        InputProps={{ inputRef: ref, disableUnderline: true }}
                        label="Preço de Custo"
                        placeholder="Preço de custo do produto"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        {...props}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Código de barras"
                    value={codBarras}
                    onChange={handleCodBarrasChange}
                    placeholder="Preço do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Descrição"
                    value={descricao}
                    onChange={handleDescricaoChange}
                    placeholder="Descrição do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Categoria"
                    value={categoria}
                    onChange={handleCategoriaChange}
                    placeholder="Categoria do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="NCM"
                    value={NCM}
                    onChange={handleNCMChange}
                    placeholder="NCM do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    className="button"
                    type="button"
                    onClick={() => history.push('/product')}
                    variant="contained"
                    disableRipple
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="button"
                    type="button"
                    onClick={handleNextStep}
                    variant="contained"
                    color="primary"
                    disabled={formErrors.nome || formErrors.preco || formErrors.estoque || formErrors.unidade}
                    style={{
                      padding: isMobile ? '8px 16px' : '12px 24px',
                      fontSize: isMobile ? '0.75rem' : '1rem',
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}
          </form>
        </StyledContainer>
        <Composition>
          {formStep === 2 && (
            <>
              <h1>Confirme dados</h1>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Nome do produto *"
                    value={nome}
                    placeholder="Nome do produto *"
                    disabled
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Preço *"
                    value={preco}
                    disabled
                    onChange={handlePrecoChange}
                    placeholder="Preço do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Qtd. Estoque *"
                    value={estoque}
                    disabled
                    onChange={handleEstoqueChange}
                    placeholder="Quantidade em estoque"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Unidade (KD, Un, Metros) *"
                    value={unidade}
                    disabled
                    onChange={handleUnidadeChange}
                    placeholder="Unitário, Quilograma, Metros"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Preço de Custo"
                    value={custo}
                    disabled
                    onChange={handleCustoChange}
                    placeholder="Preço de custo do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Código de barras"
                    value={codBarras}
                    disabled
                    onChange={handleCodBarrasChange}
                    placeholder="Preço do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Descrição"
                    value={descricao}
                    onChange={handleDescricaoChange}
                    placeholder="Descrição do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Categoria"
                    value={categoria}
                    disabled
                    onChange={handleCategoriaChange}
                    placeholder="Categoria do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="NCM"
                    value={NCM}
                    disabled
                    onChange={handleNCMChange}
                    placeholder="NCM do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    className="button"
                    type="button"
                    onClick={handlePrevStep}
                    variant="contained"
                    disableRipple
                    style={{ marginBottom: '10px' }}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="button"
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableRipple
                    style={{ marginTop: '10px' }}
                  >
                    Finalizar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Composition>
      </Container>
    </Layout>
  );
}

Register.propTypes = {
  match: PropTypes.shape({}).isRequired,
};
