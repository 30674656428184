import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../../assets/img/Logo  (1).svg';

const logoStyle = {
  width: '240px',
  height: 'auto',
  marginTop: '-20px',
};

function scrollToSection(sectionId) {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128; // Ajuste conforme necessário
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    window.scrollTo({
      top: targetScroll,
      behavior: 'smooth',
    });
  }
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 2, sm: 1 },
        py: { xs: 6, sm: 8 },
        textAlign: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: -5, mt: '-80px' }}>
        <img src={Logo} style={logoStyle} alt="logo of CyberSensei" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          textAlign: 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            minWidth: { xs: '100%', sm: '20%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            style={{ margin: '0px 15px' }}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              color: '#0a798d',
              display: 'inline-block',
              borderBottom: '2px solid #0a798d',
              pb: 1,
              mb: 1,
              width: 'fit-content',
              fontWeight: 'bold',
            }}
          >
            Menu
          </Typography>
          <MenuItem onClick={() => scrollToSection('home')} sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>
            Home
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('quem-somos')} sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>
            Quem Somos
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('soluções')} sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>
            Soluções
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('faq')} sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>
            Perguntas Frequentes
          </MenuItem>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '100%', sm: '25%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              color: '#0a798d',
              borderBottom: '2px solid #0a798d',
              pb: 1,
              mb: 1,
              width: 'fit-content',
              fontWeight: 'bold',
            }}
          >
            Contate-nos
          </Typography>
          <Box display="flex" alignItems="center">
            <PhoneIcon sx={{ mr: 1, fontSize: 24, color: '#0a798d' }} />
            <Link
              color="text.secondary"
              href="tel:+558130497999"
              sx={{ fontFamily: 'Poppins', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              +55 (81) 3088-2318
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            <WhatsAppIcon sx={{ mr: 1, fontSize: 24, color: '#0a798d' }} />
            <Link
              color="text.secondary"
              href="https://wa.me/5581996527732"
              sx={{ fontFamily: 'Poppins', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              +55 (81) 9.9922-9225
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            <EmailIcon sx={{ mr: 1, fontSize: 24, color: '#0a798d' }} />
            <Link
              color="text.secondary"
              href="mailto:contato@rcnet.com.br"
              sx={{ fontFamily: 'Poppins', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              contato@rcnet.com.br
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '100%', sm: '25%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              color: '#0a798d',
              borderBottom: '2px solid #0a798d',
              pb: 1,
              mb: 1,
              width: 'fit-content',
            }}
          >
            Siga-nos
          </Typography>
          <Box display="flex" alignItems="center">
            <InstagramIcon sx={{ mr: 1, fontSize: 24, color: '#0a798d' }} />
            <Link
              color="text.secondary"
              href="https://www.instagram.com/rcnetr/?hl=pt-br"
              sx={{ fontFamily: 'Poppins', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              @rcnetr
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            <LinkedInIcon sx={{ mr: 1, fontSize: 24, color: '#0a798d' }} />
            <Link
              color="text.secondary"
              href="https://www.linkedin.com/company/rcnet-solu%C3%A7%C3%B5es/"
              sx={{ fontFamily: 'Poppins', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              RCNet Soluções
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '100%', sm: '6%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              color: '#0a798d',
              borderBottom: '2px solid #0a798d',
              pb: 1,
              mb: 1,
              width: 'fit-content',
            }}
          >
            Onde Estamos
          </Typography>
          <Box display="flex" alignItems="center">
            <LocationOnIcon sx={{ mr: 1, fontSize: 24, color: '#0a798d' }} />
            <Typography color="text.secondary" sx={{ fontFamily: 'Poppins', fontSize: '18px' }}>
              Av. Samuel Macdowell, 20 – Jardim Primavera Camaragibe – PE 54753-685
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 2, sm: 4 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      />
      <div>
        <Typography variant="body2" color="textSecondary">
          © {new Date().getFullYear()} QuickMei. Todos os direitos reservados.
        </Typography>
      </div>
    </Container>
  );
}
